import React, { useState, useEffect } from "react"; 
import Iframe from "react-iframe";
import { useSelector } from "react-redux";
import ScratchCard from "simple-scratch-card";
import { element, isNotEmpty, percentageToVal, isEmpty } from "../../../Utils/helper"; 
import InputWithLabel from "../../Common/input/inputWithLabel";
import {  CgSpinner } from "react-icons/cg";  
import PrimaryButton from "../../Common/Button/PrimaryButton";
import { CampaignEvents } from "../../../../backend/campaignEvents";
import { GetDEviceInfo } from "../../../../backend/GetDeviceInfo";
import CheckBox from "../../Common/input/Checkbox";
import TextareaInput from "../../Common/input/textarea";
import { getImageBase64 } from "../../../../backend/templates";
import "./style.css"

const DesktopWheelSideTab = ({ data },ref) => { 

  const {campaignID,siteId,renderType, primary_Site_id} = useSelector((state) => ({campaignID:state.campaign_id,siteId:state.site_id,renderType:state.renderType, primary_Site_id:state.primary_Site_id}))
  const {Settings,leadSettings,GameFinsh, setLoadContent,DIVID, submit, BrandLabel=true} = data 
  const [panelSize, setPanelSize] = useState(400);  
  const [rightSize, setrightSize] = useState(200);  
  const [circleSize , setCircleSize] =useState(200);
  const [isSizeLoaded, setSizeLoaded]= useState(false);
  const [isFrameLoaded,setFrameLoaded] = useState(false) 

  const [blur, setBlur] =useState([false,false]);
  const [btnText, setBtnText] =useState(leadSettings?.buttenText)
  const [showForm,setshowForm] = useState(true)
  const [result,setResult] = useState([false,{}])
  const [isCopied,setCopyStatus] = useState(false)  
  const [isWaterMark,setWaterMark] = useState(true)  
  
  const [isfilled, isFulfilled] =useState(false)
  const [formobject,setformobject] = useState({}) 
  const [couponBg,setcouponBg] = useState("") 
  const [resultLoaded,setResultLoaded] = useState(false) 
  const [isBase64Loading, setBase64Loading]=useState(false)
  const [base64, setBase64]=useState("")
  const [scratchSize, setScratchSize]=useState([250,250])
  const [event, setEvent]=useState(null)
  const [cnt, steCnt]=useState(0) 


  
  useEffect(()=>{ if(isBase64Loading)setWaterMark(false); else setWaterMark(true); },[showForm,isBase64Loading])     
  useEffect(()=>{if(isfilled) spinTheWheel()},[isfilled])
  useEffect(()=>{if(isFrameLoaded && isSizeLoaded)   setLoadContent(false) ; },[isFrameLoaded,isSizeLoaded])

  useEffect(()=>{
    var frame=element("spinIframe"+DIVID);
    frame.contentWindow.Settings = Settings;
    frame.contentWindow.DIVID = DIVID;

    setTimeout(()=>{
      element("spinIframe"+DIVID).contentWindow.postMessage("CircleSize"+DIVID,"*"); 
    setSizeLoaded(true)
    },100)

    initResult()
  },[])

  useEffect(()=>{ if(resultLoaded){ submitForm(result[1]?.resultText) } },[resultLoaded,result])

  function updateSize() {
    let gamePanelHeight=element("gamePanel"+DIVID).clientHeight
    let gamePanelWidth=element("gamePanel"+DIVID).clientWidth
    let rightAreaHeight=element("rightArea"+DIVID).clientHeight
    let rightAreaWidth=element("rightArea"+DIVID).clientWidth
    let couponWidth=percentageToVal(gamePanelWidth/2,90), couponHeight=percentageToVal(gamePanelWidth/2,80)
    if(couponWidth>450) couponWidth=450
    if(couponHeight>350) couponHeight=350
    if(couponWidth<279) couponWidth=279
    if(couponHeight<248) couponHeight=248
    setScratchSize([couponWidth,couponHeight])
    setPanelSize([gamePanelWidth,gamePanelHeight])

    let temp;
    if(rightAreaHeight<rightAreaWidth) temp=rightAreaHeight
    if(rightAreaWidth<rightAreaHeight) temp=rightAreaWidth
    if(temp<330) temp=330
    if(temp>560) temp=560
    // temp=temp*1.7
    temp+=200
    element("spinIframe"+DIVID).contentWindow.postMessage("CircleSize"+DIVID,"*"); 
    setrightSize([temp,temp+10])
  }

  // GET PANEL AND CIRCLE SIZE 
  useEffect(() => {
    // GET CIRCLE SIZE
    function onSizeMessage(event) {  
      var data = event?.data;  
      if(isEmpty(data)) return;
      if (data?.func === "circleSize"+DIVID)   {
        let tempCircleHeight=data?.message/2 || 200
         setCircleSize(tempCircleHeight)
    } 
    }

      window.addEventListener('resize', updateSize);
      window.addEventListener("message", onSizeMessage, false) 
      updateSize();

      return () => {
        window.removeEventListener('resize', updateSize);
        window.removeEventListener('message', onSizeMessage);
      }
    },[]);

  
    function initResult () {
      if(window.addEventListener)   window.addEventListener("message", onMessage, false) 
      else if (window.attachEvent)  window.attachEvent("onmessage", onMessage, false) 

      function onMessage(event) {  
        //if (event.origin !== "http://example.com") return; 
        var data = event.data;  
        if (data?.func === "stopGame"+DIVID)   {
          setBlur([false,false]);
          setBtnText(leadSettings?.buttenText)
          let dataResult=JSON.parse(data?.message);
          let index = Settings?.segmentValuesArray?.findIndex(function(obj) {
            return obj?.slicenumber === dataResult.results[0]?.winningSliceNumber
          });
          let tempResult=Settings?.segmentValuesArray[index]
          let tempCouponBg=(tempResult?.couponBgType==="image")? "url("+tempResult?.couponBgImage+") 0% 0% / 100% 100% no-repeat" : tempResult?.couponBgColor || "#f5f5dc"; setcouponBg(tempCouponBg);
          setResult([ dataResult.results[0]?.win ,tempResult] )
          setshowForm(false) 
          setResultLoaded(true)  
          loadBase64(tempResult?.couponStauts,tempResult?.couponScratchImage);
          // console.log(result) 
        }
      } 

      async function loadBase64(couponStauts,imgUrl){
        if(couponStauts){
          let base64Temp= await getImageBase64(imgUrl || "https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/scratchCard.png",600,600)
          setBase64(base64Temp)
          steCnt(o => o+1);
        } 
          setTimeout(()=>{ setBase64Loading(false); },100)
      }
    }
 

    function spinTheWheel(){
      if(!isfilled) return
      setBase64("")
      setResultLoaded(false);
      setBase64Loading(true)
      setBlur([false,true]);
      setBtnText("Please Wait ...")
      var frame=element("spinIframe"+DIVID);
      frame.contentWindow.postMessage("spinTheWheel","*");
    }

    async function submitForm(coupon){
      if(renderType==="liveRender"){
        try{ 
          let newFormObj=formobject
          newFormObj["Win Coupon"]=coupon          
            const CampaignEvent = new CampaignEvents({campaignId:campaignID,site_id:siteId, primary_Site_id:primary_Site_id})        
            const DEviceData = await GetDEviceInfo()
          CampaignEvent.subcripe(newFormObj,encodeURIComponent(event),DEviceData )
                 
        }catch(e){
            console.log(e)     
        }} else{
            console.log("In Preview")
            
        }
      setformobject({})
    }


    const OnSSubmitForm = async (e) => { 
      e.preventDefault()
      isFulfilled(true)
      setEvent(e);
      submit()
      }

    const Onchange = (e,name) => {
      let lowercasename = String(name).toLocaleLowerCase()
      let temp = formobject
      temp[lowercasename] = e.target.value;
      setformobject(temp) 
    }  

    const copy = () => { 
      navigator.clipboard.writeText(result[1]?.resultText); 
      setCopyStatus(true)
      setTimeout(()=>{ setCopyStatus(false) },2100)
    }

return ( 
  <div className="w-full h-[100vh]  flex flex-row overflow-hidden" id={"gamePanel"+DIVID}>

    {/* LEFT UI   */}
      <div className={`w-[50%] ${blur[0]?"opacity-[0.4]":""} `} id={"rightArea"+DIVID}>
      <Iframe onLoad={()=>{setFrameLoaded(true);updateSize()}} 
          className="overflow-hidden rotate-[55deg]" title="Spin Wheel" 
          id={"spinIframe"+DIVID} src={"/asset/game/SpinWheel/sideTab.html"} 
          width={(rightSize[0])+"px"} height={rightSize[1]+"px"} 
          styles={{top:(panelSize[1]/2 - circleSize)+"px", right:(rightSize[0]/2)-(rightSize[0]/20)+"px", position:"relative"}}></Iframe> 
      </div>


    {/* RIGHT UI */}
      <div className={`w-[50%] flex flex-col items-center justify-center pr-[0.75rem] sm:pr-[1rem] ${blur[1]?"opacity-[0.4]":""} `} >         
        { 
        showForm ?
            <div className="w-full h-fit">
                <div className="title-text "><p><strong  className=" text-[21px] sm:text-[26px] lg:text-[30px] text-center"  style={{color:leadSettings?.titleColor || "#000000"}} >{leadSettings?.title}</strong></p></div>
                <form className="flex  flex-col space-y-4   overflow-auto h-full p-1 min-h-[250px]"  onSubmit={OnSSubmitForm} style={{maxHeight:Math.round(panelSize[1]/2)+"px"}} > 
                
                {
                  leadSettings?.field?.map((list,key)=>{
                  let opts={}
                  if(list?.required) opts["required"]="required"
                  return <> { 
                      list?.inputType === 'checkbox'  ?
                      <CheckBox className="max-w-[580px]" key={"InputWithLabel_"+(key+=1)} Placeholder={list?.Label} label={list?.Label} name={list?.Label} onchange={(e)=>Onchange(e,list?.Label)} type={list?.inputType}   {...opts} isUrl={isNotEmpty(list?.checkBoxRedirUrl)} redirUrl={list?.checkBoxRedirUrl}/> :
                      list?.inputType === 'textarea' ?
                      <TextareaInput className="max-w-[580px]" key={"InputWithLabel_"+(key+=1)} Placeholder={list?.Label} label={list?.Label} name={list?.Label} onchange={(e)=>Onchange(e,list?.Label)} type={list?.inputType}   {...opts} /> :
                      <InputWithLabel  padding="p-[1.25rem]" className="max-w-[580px]" key={"InputWithLabel_"+(key+=1)} Placeholder={list?.Label} label={list?.Label} name={list?.Label} onchange={(e)=>Onchange(e,list?.Label)} type={list?.inputType}   {...opts} /> 
                    }</>
                  })
                } 

                <PrimaryButton OnClick={spinTheWheel} style={{backgroundColor:Settings?.pegFill}} ring={" focus:ring-1 focus:ring-offset-1 focus:ring-[#e96d76] "} classNames={"max-w-[580px] p-[1.25rem]"}
                text={btnText} id={"submitBtn"+DIVID} type="submit"  />

                </form> 
                {/* POWER BY GOZEN */}
                {BrandLabel &&
                <div className="w-full flex justify-end max-w-[580px]"><div className="flex w-fit flex-col space-y-2  bg-white rounded-lg py-1 sm:py-2 h-fit px-2 sm:px-4 my-auto shadow-sm "><p className="flex flex-row space-x-1 sm:space-x-2 sm:text-[12px] leading-[17px] md:text-[14px] sm:leading-[20px] m-auto"><span className="my-auto">Powered By</span> <span className="font-bold my-auto sm:text-sm md:text-base">GoZen</span></p></div> </div>
                }
            </div>
        :
            <div>
              {/* COUPON OR NORMAL RESULT */}
            { result[1]?.couponStauts ?
          <>
            { isNotEmpty(result[1]?.couponScratchImage)   &&
            <div className="SpinScratchCardStyle" style={{ touchAction:"none",	boxShadow:(isBase64Loading? "unset" : "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)")}} >        
                  {resultLoaded  &&
                      <div  id={"scratchCardStyle"+DIVID} className=" flex h-full w-full"  key={cnt}>
                      
                      {isNotEmpty(base64) && isBase64Loading===false ? 
                      <ScratchCard key={cnt}  cover={base64}  percent={45} width={scratchSize[0]} height={scratchSize[1]}  onComplete={(e) => { }}> 
                        <div className="scratchCardInner flex flex-col justify-center overflow-auto items-center "  style={{background:couponBg}} >
                          {isNotEmpty(result[1]?.prizetext) && <div><h3 className=" text-[#06152D] mb-[10px] font-bold noselect" >{result[1]?.prizetext}</h3></div>}
                          {isNotEmpty(result[1]?.resultText) && <div className="text-[14px] text-[#06152D] mt-[12px] noselect">{isCopied? "Copied!" :  "Coupon Code" }</div> }
                          {isNotEmpty(result[1]?.resultText) && <div className="w-full mt-[5px] cursor-pointer mb-[10px]"  onClick={()=>copy()}><p className="flex-row flex-wrap text-[#06152D] border-dashed border-2 border-gray-300 m-auto   text-[24px] w-fit flex justify-center font-bold bg-gray-200 p-[3px] rounded-md  min-w-[150px] " > {((result[1]?.resultText).split(""))?.map((text,inxKey)=>{ return <span  key={inxKey} className="ml-[4px] mr-[4px]">{text}</span> })} </p></div>}
                          {isNotEmpty(result[1]?.redirURL) && <div className="text-[14px] text-[#000053] mt-[12px] noselect font-extrabold cursor-pointer" onClick={()=>{window.open(result[1]?.redirURL,"_blank")}}>{result[1]?.redirBtnTxt ?? "Click Now!"}</div> }
                          {isNotEmpty(result[1]?.couponDescription) && <div className="w-full mt-[15px] text-[#06152D] noselect">  <center>  {result[1]?.couponDescription}</center></div>}
                        </div>
                      </ScratchCard>
                      :
                      <div className="flex items-center m-auto">  <CgSpinner className="w-8 h-8 animate-spin text-primary" /> <p className="text-sm font-medium m-2" > {"Loading"}  </p> </div>
                      }

                      </div>
                  }
             </div>            
            }
          </> :
            <div>   
              <div className="title-text w-full max-w-[600px] flex flex-col items-center space-y-4 text-center"><p><strong  className=" text-[21px] sm:text-[26px] lg:text-[30px] text-center" style={{color:leadSettings?.titleColor || "#000000"}} >{result[1]?.prizetext}</strong></p></div>
                { 
                  result[0] && isNotEmpty(result[1]?.resultText) &&
                    <div className="w-full max-w-[600px] flex flex-col items-center space-y-4">
                        <div  >Copy your coupon code now.</div>
                        <div  className="min-w-[200px] sm:min-w-[250px] md:min-w-[350px] bg-white rounded-md  p-1 md:p-2 border-[3px]  border-dotted text-center text-[14px] sm:text-[17px] md:text-[20px]  break-all" style={{borderColor:Settings?.pegFill}}>{result[1]?.resultText}</div>
                        <div><PrimaryButton style={{backgroundColor:Settings?.pegFill}} ring={" focus:ring-1 focus:ring-offset-1 focus:ring-[#e96d76] "} classNames={"w-[200px] sm:w-[250px] md:w-[350px] max-w-[550px]"} text={isCopied ? "Copied" :"Copy"}  type="button"   OnClick={()=>copy()} /></div>
                    </div>
                }
                   { isNotEmpty(result[1]?.redirURL) && 
                        <div className="w-full max-w-[600px] flex flex-col items-center space-y-4">
                        <div className="mt-4"><PrimaryButton style={{backgroundColor:Settings?.pegFill}} ring={" focus:ring-1 focus:ring-offset-1 focus:ring-[#e96d76] "} classNames={"w-[200px] sm:w-[250px] md:w-[350px] max-w-[550px]"} text={result[1]?.redirBtnTxt ?? "Click Now!"}  type="button"   OnClick={()=>{window.open(result[1]?.redirURL,'_blank')}} /></div>
                        </div>
                        }
            </div> 
            }
            { (BrandLabel && isWaterMark )  && <div className="w-full flex justify-end max-w-[580px] mt-[22px]"><div className="flex w-fit flex-col space-y-2  bg-white rounded-lg py-1 sm:py-2 h-fit px-2 sm:px-4 my-auto shadow-sm "><p className="flex flex-row space-x-1 sm:space-x-2 sm:text-[12px] leading-[17px] md:text-[14px] sm:leading-[20px] m-auto"><span className="my-auto">Powered By</span> <span className="font-bold my-auto sm:text-sm md:text-base">GoZen</span></p></div></div> }

            </div>
        }
      </div>
  </div>
);}; 
export default React.forwardRef(DesktopWheelSideTab);