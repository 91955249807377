import React, { useEffect, useState } from "react";

export const ConnectionGameAnswerFailed = ({

    WrongAnswerFun = null,
    wrongAns = false,
    connectionObj = {}
}) => {

    const { invalidAnswerImg, invalidanswerContent } = connectionObj


    const [Trigger, SetTrigger] = useState(false);

    useEffect(() => {

        setTimeout(() => SetTrigger(true), 200)
        setTimeout(() => SetTrigger(false), 1500)
        setTimeout(() => WrongAnswerFun(false), 2200)

    }, [])

    // setTimeout( () => { SetTrigger(true) }, 2000 )
    // setTimeout( () => { SetTrigger(false) }, 3000 )

    return (
        <div className={`absolute lef transition-all duration-500 ${Trigger ? "top-0 " : "-top-52"} w-full`} >

            <div className=" w-fit flex space-x-2 mx-auto p-2 px-4 bg-white rounded-xl mt-4 border-1 border-red-500 shadow-red-500" style={{ boxShadow: "0px 0px 5px 8px #ef444450" }} >

                <p className="my-auto" >{invalidanswerContent ?? "oops!. Wrong Answer. Try once again."}</p>

                {
                    

                    <div className="w-8 h-8" >

                        <img src={invalidAnswerImg ?? "/asset/images/close-circle.png"} className="w-full h-full object-cover" alt="" title="" />
                    </div>

                }
            </div>

        </div>
    )

}