import React, { useEffect, useState } from "react";
import PrimaryButton from "./Button/PrimaryButton";
import { fadeOut, isNotEmpty, isTrue, onImageError, percentageToVal } from "../../Utils/helper";
import { CgCopy, CgCheckO, CgSpinner } from "react-icons/cg";
import { PartyPaperInit } from "./PartyPaper";
import ScratchCard from 'simple-scratch-card';
import { getImageBase64 } from "../../../backend/templates";
import { useDispatch, useSelector } from "react-redux";



export const ThankYouPage = ({ gamePlaySeconds = 0, templateType = "", timerstatus = false, score = 0, ActionCount = 0, timing = '0', restartAction = null, ThankYouPageObj = {}, DIVID = "" }) => {

  if (templateType === "findpath") { score = gamePlaySeconds };

  const { campaignSettings } = useSelector(state => ({ campaignSettings: state.campaignSettings }));

  const { scoreContent, TimingContent } = campaignSettings?.defaultContent;

  const { fontSize } = campaignSettings;



  const [isCopied, setCopyStatus] = useState(false)
  const [resultInx, setResultInx] = useState(0)
  const [resultLoaded, setResultLoaded] = useState(false)
  const [base64, setBase64] = useState("")
  const [scratchSize, setScratchSize] = useState([250, 250])
  const [isBase64Loading, setBase64Loading] = useState(false)
  const [cnt, steCnt] = useState(0)
  const [isScratchOpened, SetscratchOpened] = useState(false)


  const width = document.querySelector("#gameFrameBorder" + DIVID).clientWidth;
  const height = document.querySelector("#gameFrameBorder" + DIVID).clientHeight;
  const couponBg = (ThankYouPageObj[resultInx]?.couponBgType === "image") ? "url(" + ThankYouPageObj[resultInx]?.couponBgImage + ") 0% 0% / 100% 100% no-repeat" : ThankYouPageObj[resultInx]?.couponBgColor || "#f5f5dc"

  const deployTheRestartAction = () => {

    setResultLoaded(false)

    setTimeout(()=>{ restartAction() },500)  
    //redirectAction()
  }

  // Party papper loading
  useEffect(() => {
    setBase64Loading(true)
    let clearOn = 70000
    PartyPaperInit({ canvasId: "confetti" + DIVID, width: width, height: height, count: 60, clearOn: clearOn })
    setTimeout(() => { fadeOut("confetti" + DIVID) }, clearOn - 1350)
    selectTheBestResult()

    var couponWidth = document.querySelector("#scratchCardStyle" + DIVID)?.clientWidth || 150;
    var couponHeight = document.querySelector("#scratchCardStyle" + DIVID)?.clientHeight || 150
    setScratchSize([couponWidth, couponHeight])
  }, [])

  // Choosing the best result by checking the condition
  const selectTheBestResult = () => {
    ThankYouPageObj.map((obj, inx) => {
      let opertator = obj?.score?.opertator, resultValue = parseInt(obj?.score?.value)
      score = parseInt(score)

      if ((opertator === "equal to" && score === resultValue) ||
        (opertator === "graterthen" && score > resultValue) ||
        (opertator === "lessthen" && score < resultValue)) {
        setResultInx(inx);
        setResultLoaded(true)
        SetscratchOpened(true)
        return inx;
      }
    })
  }

  // Loading Scratch Card Image and Size
  useEffect(() => {
    if (resultLoaded) {
      loadBase64()
      SetscratchOpened(true)
    }
  }, [resultInx, resultLoaded])


  async function loadBase64() {
    var couponWidth = document.querySelector("#scratchCardStyle" + DIVID)?.clientWidth || 150;
    var couponHeight = document.querySelector("#scratchCardStyle" + DIVID)?.clientHeight || 150
    setScratchSize([couponWidth, couponHeight])
    let base64Temp = await getImageBase64(ThankYouPageObj[resultInx]?.couponScratchImage || "https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/scratchCard.png", 600, 600)
    setBase64(base64Temp)
    steCnt(o => o + 1);
    setTimeout(() => { setBase64Loading(false) }, 200)
  }



  // Other Functions
  const redirectAction = () => { if (isNotEmpty(ThankYouPageObj[resultInx]?.reDirUrl)) window.location.href = ThankYouPageObj[resultInx]?.reDirUrl }
  
  const copy = () => {
    navigator.clipboard.writeText(ThankYouPageObj[resultInx]?.couponCode);
    setCopyStatus(true); setTimeout(() => { setCopyStatus(false) }, 2100)
  }


  const TitleSize = {
    "small": "text-[18px] md:text-[22px]",
    "medium": "text-[22px] md:text-xl",
    "large": "text-[24px] md:text-[26px]"
  }

  const DescriptionSize = {
    "small": "text-[12px] md:text-sm",
    "medium": "text-sm md:text-base",
    "large": "text-base md:text-[20px] font-medium"
  }

  const ButtonSize = {
    "small": "text-[12px]",
    "medium": "text-sm",
    "large": "text-lg"
  }

  const parSize = {
    "small": "text-sm",
    "medium": "text-base",
    "large": "text-lg"
  }

  return (
    <div className={` absolute transition-all duration-700 ${ resultLoaded ? "bg-[#ffffff50]"  : "bg-[#ffffff00]"} flex left-0 h-full top-0 w-full justify-center  bg overflow-auto `}   >


      <div
        style={{ background: "white" }}
        className={` transform  transition-all duration-700 ${ resultLoaded ? "scale-100" : "scale-0" } z-10 w-[90%] sm:w-[90%] lg:w-[80%] xl:w-[75%] h-fit flex flex-col  bg-no-repeat border-1 rounded-2xl px-4 py-5  sm:px-8 sm:py-10  space-y-6 my-auto  justify-center`}>

        {isNotEmpty(ThankYouPageObj[resultInx]?.titleContent) && <p className={`text-center font-semibold  ${TitleSize[fontSize]} `}>{ThankYouPageObj[resultInx]?.titleContent}</p>}

        {isNotEmpty(ThankYouPageObj[resultInx]?.descriptionContent) && <p className={`text-center ${DescriptionSize[fontSize]}`}>{ThankYouPageObj[resultInx]?.descriptionContent}</p>}

        {isNotEmpty(ThankYouPageObj[resultInx]?.imageUrl) && <div className="w-[360px] mx-auto rounded-xl">

          <img src={ThankYouPageObj[resultInx]?.imageUrl} onError={onImageError} alt={"thumbnail"} className="w-full rounded-xl" />

        </div>}

        {isTrue(ThankYouPageObj[resultInx]?.ScoreStauts) && (templateType !== "findpath") && <p className="text-center"> Score : <span>{score}</span></p>}

        {timerstatus && <p className="text-center"> Timing : <span>{timing}</span></p>}

        {isTrue(ThankYouPageObj[resultInx]?.reStart) ? <PrimaryButton text={ThankYouPageObj[resultInx]?.restartbutton} textsize={ButtonSize[fontSize]} width={'w-28'} margin={'mx-auto'} OnClick={deployTheRestartAction} /> :
          <PrimaryButton text={ThankYouPageObj[resultInx]?.restartbutton} width={'w-28'} textsize={ButtonSize[fontSize]} margin={'mx-auto'} OnClick={() => redirectAction()} />}

        {/* { ThankYouPageObj[resultInx]?.buttonDisplayView && <PrimaryButton text={ThankYouPageObj[resultInx]?.buttontextL} width={'w-28'} margin={'mx-auto'}  /> } */}

      </div>



      <>{isScratchOpened && isTrue(ThankYouPageObj[resultInx]?.couponStauts) && isNotEmpty(ThankYouPageObj[resultInx]?.couponScratchImage) &&

        <div className="scratchCardStyle" >
          <svg onClick={() => { SetscratchOpened(false) }} className="engage-close-btn" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.825 6.18562C19.4025 5.76312 18.72 5.76312 18.2975 6.18562L13 11.4723L7.70253 6.17479C7.28003 5.75229 6.59753 5.75229 6.17503 6.17479C5.75253 6.59729 5.75253 7.27979 6.17503 7.70229L11.4725 12.9998L6.17503 18.2973C5.75253 18.7198 5.75253 19.4023 6.17503 19.8248C6.59753 20.2473 7.28003 20.2473 7.70253 19.8248L13 14.5273L18.2975 19.8248C18.72 20.2473 19.4025 20.2473 19.825 19.8248C20.2475 19.4023 20.2475 18.7198 19.825 18.2973L14.5275 12.9998L19.825 7.70229C20.2367 7.29062 20.2367 6.59729 19.825 6.18562Z" fill="#586474"></path></svg>

          {resultLoaded &&
            <div id={"scratchCardStyle" + DIVID} className="scratchCardParent flex h-full w-full" key={cnt + "-" + resultInx}>
              {isBase64Loading ? <div className="flex items-center m-auto"> <CgSpinner className="w-8 h-8 animate-spin text-primary" /> <p className="text-sm font-medium m-2" > {"Loading"}  </p> </div>
                :
                <ScratchCard
                  key={cnt + "-" + resultInx}
                  cover={base64}
                  percent={45}
                  width={scratchSize[0]}
                  height={scratchSize[1]}
                  onComplete={(e) => {
                    // console.log(e)
                  }}
                >

                  <div className="scratchCardInner flex flex-col justify-center items-center " style={{ background: couponBg }} >

                    {isNotEmpty(ThankYouPageObj[resultInx]?.couponTitle) && <div><h3 className=" text-[#06152D] mb-[10px] font-bold noselect" >{ThankYouPageObj[resultInx]?.couponTitle}</h3></div>}
                    {isNotEmpty(ThankYouPageObj[resultInx]?.couponCode) && <div className="text-[14px] text-[#06152D] mt-[12px] noselect">{isCopied ? "Copied!" : "Coupon Code"}</div>}
                    {isNotEmpty(ThankYouPageObj[resultInx]?.couponCode) && <div className="w-full mt-[5px] cursor-pointer mb-[10px]" onClick={() => copy()}><p className="flex-row flex-wrap text-[#06152D] border-dashed border-2 border-gray-300 m-auto   text-[24px] w-fit flex justify-center font-bold bg-gray-200 p-[3px] rounded-md  min-w-[150px] " > {((ThankYouPageObj[resultInx]?.couponCode).split(""))?.map((text, inxKey) => { return <span key={inxKey} className="ml-[4px] mr-[4px]">{text}</span> })} </p></div>}
                    {isNotEmpty(ThankYouPageObj[resultInx]?.couponDescription) && <div className="w-full mt-[15px] text-[#06152D] noselect">  <center>  {ThankYouPageObj[resultInx]?.couponDescription}</center></div>}

                  </div>




                </ScratchCard>
              }
            </div>}
        </div>

      }</>


      <canvas className="absolute" id={"confetti" + DIVID}></canvas>

    </div>
  )
}


