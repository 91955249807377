import React, { useEffect, useState } from "react";
import PrimaryButton from "../../Common/Button/PrimaryButton";


export const ConntectionAnserCorrect = ({
    
    correctAnswerFun = null,
    connectionObj={}

}) => {

    const { answer, answerImg } = connectionObj

    const [Trigger, SetTrigger] = useState(false);

    useEffect(() => {

        setTimeout(() =>  SetTrigger(true) , 250);

    }, [])

    const triggernext = ( )=>{ 

        SetTrigger(false);
        
        setTimeout(() =>  correctAnswerFun() , 900);

    }

    return (

        <div className={` absolute flex w-full h-full transition-all ${Trigger ? "bg-[#00000040]" : "bg-[#00000000]"} duration-700 bottom-0 top-0 right-0`} style={{ zIndex:'1' }} >

            <div className={`w-3/5 m-auto flex flex-col bg-white max-w-[460px] transform space-y-2 transition-all duration-700 shadow-lg border-1 border-green-500 ${Trigger ? "scale-100" : "scale-0"} p-3 px-4 rounded-xl `} style={{ boxShadow:"0px 0px 10px 5px #22c55e50" }} >

                <p className="text-center font-semibold text-lg" >The Correct Answer is</p>

                <div className="border-1 flex rounded-lg h-[240px]  w-full overflow-hidden object-cover relative">

                    <img src={ answerImg ? answerImg :  "/asset/images/emptyimage.png"}  className="object-cover w-fit h-auto flex m-auto rounded-lg" title="" alt="" />

                </div>

                <p className="text-center font-semibold text-[40px]" >{answer}</p>
                
                <PrimaryButton text={"Next"} OnClick={triggernext} />

            </div>

        </div>
    )

}