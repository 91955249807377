import React, { useState, useEffect, useImperativeHandle } from "react";
import { isNotEmpty, allElement, element } from "../../../Utils/helper";
import "../../Common/commonStyle.css";
import "./style.css"
import { ConnectionGameAnswerFailed } from "./ConnectionAnswerFailed";
import { ConntectionAnserCorrect } from "./ConnectionAnswerCorrect";

const Connection = ({ data }, ref) => {
  const { Settings, setActionCount, GameFinsh, setLoadContent, DIVID } = data;

  const { TimeOut , ConnntionStartTimeFun, ConnectionTimeOutFun, pauseTimer , startTimeFun =null } = data;


  const [curQuizIndex, setCurQuizIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [connectionList, setconnectionList] = useState([]);

  const [ wrongAnswer, setWrongAnswer ] = useState(false); 
  const [ correctAnswer, setCorrectAnswer ] = useState(false); 
  const [ fideout, setfideout ] = useState(false); 
  const [ActiveObj, setActiveObj] = useState({})
  const [trigger, settrigger] = useState(false)

  const [htmlStr, sethtmlStr] = useState(null);
  const [widthPer, setwidthPer] = useState(20);

  useImperativeHandle(ref, () => ({ 
    GameStart: async () => { 
      setfideout(false);
      settrigger(!trigger);
      gameSetup(); 
      setActiveObj(Settings?.connectionList[0])
      setconnectionList(Settings?.connectionList); /* await preLoad();*/ }, 
    getScore: () => { return score } 
  }));
  
  useEffect(() => { if (Settings?.connectionList?.length > 0) { setCurQuizIndex(0); setActionCount(0); gameSetup(); } }, [Settings, trigger]);

  useEffect(() => { if (Settings?.connectionList?.length !== 0 && curQuizIndex >= Settings?.connectionList?.length) GameFinsh(true); }, [curQuizIndex]);
  useEffect(() => { gameSetup(); }, []);

  useEffect(()=> { 
    if(TimeOut) setCorrectAnswer(true) 
  },[TimeOut])


  const gameSetup = async () => { setLoadContent([]); setCurQuizIndex(0); setScore(0); setLoadContent(false); };


  function listenKeyBackSpace(e) {
    if (e.code === "Backspace") {
      let inputBox = allElement(".charInput" + DIVID), inputboxno = parseInt(e.target.getAttribute("inputboxno"));
      if (inputboxno > 0) { inputBox[inputboxno - 1].focus(); inputBox[inputboxno - 1].value = ""; } else
        if (inputboxno === 0) inputBox[inputboxno].value = "";
    }
  }

  function listenKeyBoard(e, i) {

    // sethtmlStr(i)

    let allowInput = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
      "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
      "1", "2", "3", "4", "6", "7", "8", "9", "0", "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "-", "+", "=", "{", "}",
      "[", "]", "|", ";", ":", '"', "'", "<", ">", ",", ".", "?", "/", " "]
    let notAllowButton = ["Backspace", "AudioVolumeMute", "AudioVolumeDown", "AudioVolumeUp", "NumLock", "ScrollLock", "MetaLeft",
      "Insert", "Escape", "F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9", "F10", "F11", "F12", "Undo", "Backquote", "Tab", "CapsLock", "ShiftLeft",
      "ShiftRight", "AltLeft", "AltRight", "ControlRight", "ArrowRight", "Delete", "ArrowLeft", "ControlLeft", "MetaLeft", "ArrowUp", "ArrowDown", "PageUp", "PageDown", "Home", "End"];

    let inputBox = allElement(".charInput" + DIVID), inputboxno = parseInt(e.target.getAttribute("inputboxno")), userAnswer = "";

    if (isNotEmpty(e?.code)) { //Hardware Keyboard 
      if (e.code === "Delete") inputBox[inputboxno].value = ""; else
        if (e.code === "ArrowLeft" && inputboxno > 0) inputBox[inputboxno - 1].focus(); else
          if (e.code === "ArrowRight" && (inputboxno + 1) < connectionList[curQuizIndex]?.answer.length) inputBox[inputboxno + 1].focus(); else
            if (!notAllowButton.includes(e.code) && (inputboxno + 1) < connectionList[curQuizIndex]?.answer.length) { inputBox[inputboxno].value = e.key; inputBox[inputboxno + 1].focus(); }
    } else { // Touch Keyboard
      let elm = e?.target
      if (!notAllowButton.includes(elm?.value)) {
        if (elm?.value?.length > 1) { elm.value = elm?.value[elm?.value?.length - 1]; inputBox[inputboxno + 1]?.focus(); } else
          if (allowInput.includes(elm?.value) && (inputboxno + 1) <= connectionList[curQuizIndex]?.answer.length) { inputBox[inputboxno].value = elm?.value; inputBox[inputboxno + 1]?.focus(); }
          else { elm.value = ""; }
      }
    }

    //Check Answer
    inputBox.forEach(function (boxValue) { userAnswer += boxValue.value; });
    if (userAnswer.length === connectionList[curQuizIndex]?.answer.length) checkAnswer(userAnswer);
  }

  // const checkAnswer = async (userAnswer) => {

  //   element("gamePanel" + DIVID).style.display = "none"
  //   if (isNotEmpty(userAnswer) && userAnswer.toLowerCase() === (connectionList[curQuizIndex]?.answer).toLowerCase()) setScore(obj => obj + 1);
  //   allElement(".charInput" + DIVID)[0].focus();
  //   // let temp=allElement(".imageNameSrc"+DIVID);
  //   // for(let i=0; i<temp.length;i++){   temp[i].src=`iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP89efffwAJ4QP1FpRm6AAAAABJRU5ErkJggg==
  //   // `   } 

  //   setCurQuizIndex(curQuizIndex + 1);
  //   setActionCount(obj => obj + 1)
  //   allElement(".charInput" + DIVID).forEach(function (boxValue) { boxValue.value = ""; });

  //   element("gamePanel" + DIVID).style.display = "block"
  //   allElement(".charInput" + DIVID)[0].focus();
  // }

  const checkAnswer = async (userAnswer) => {

    if (isNotEmpty(userAnswer) && userAnswer.toLowerCase() !== (connectionList[curQuizIndex]?.answer).toLowerCase()) {
      setWrongAnswer(true)
      return;
    }

    if (isNotEmpty(userAnswer) && userAnswer.toLowerCase() === (connectionList[curQuizIndex]?.answer).toLowerCase()) {
      setCorrectAnswer(true);
      ConnntionStartTimeFun(false);
      ConnectionTimeOutFun(false)
      setScore(obj => obj + 1);
      return;
    }


  }

  const TriggerNextContent = () =>{

    setCorrectAnswer();

    // element("gamePanel" + DIVID).style.display = "none";

    setfideout(true)

    setTimeout(() => {
      
    allElement(".charInput" + DIVID)[0].focus();

    setCurQuizIndex(curQuizIndex + 1);

    if (Settings?.connectionList.length > curQuizIndex + 1) setActiveObj(Settings?.connectionList[curQuizIndex + 1])

    setActionCount(obj => obj + 1)

    allElement(".charInput" + DIVID).forEach(function (boxValue) { boxValue.value = ""; });

    // element("gamePanel" + DIVID).style.display = "block";

    allElement(".charInput" + DIVID)[0].focus();

  }, 700);

    setTimeout( () => {

      if( (curQuizIndex+1) < Settings?.connectionList?.length) {

        setfideout(false);

        startTimeFun();

      }


    }, 1000 );

    

  }

  let inputBoxes = [], key = 0;
  for (var i = 0; i < connectionList[curQuizIndex]?.answer.length; i++) {
    inputBoxes.push(<input type="text" className={`charInput` + DIVID + ` charInputStyle w-[2rem] h-[2.3rem] text-[32px] 
    rounded-[6px] sm:w-[3.2rem] sm:h-[3.5rem] sm:rounded-[10px] sm:text-[42px] mt-2 `} key={"charInput_" + i}
      maxLength={1} max={1} inputboxno={i} onKeyUp={(e) => listenKeyBoard(e, i)} onKeyDown={(e) => listenKeyBackSpace(e)} />);
  }



  return (
    <>

{ wrongAnswer && <ConnectionGameAnswerFailed WrongAnswerFun={setWrongAnswer}  wrongAns ={wrongAnswer} connectionObj = {ActiveObj} /> }

{ correctAnswer && <ConntectionAnserCorrect correctAnswerFun={ TriggerNextContent } connectionObj = { ActiveObj } /> }


    <div className={`transform transition-all duration-500 w-full h-full m-auto ${ fideout ? "opacity-0" :"opacity-100" } `} id={"gamePanel" + DIVID}>

      <div className="flex flex-row text-[#0A2551] break-all font-bold text-lg sm:text-2xl mt-[2rem] sm:mt-4 justify-center mb-12">

        {connectionList[curQuizIndex]?.question}</div>

      <div className={""}>

        <div className={`flex flex-wrap justify-center  gap-5  connectionDivParent`}>
          {
            connectionList[curQuizIndex]?.imageUrl?.map((url, inx) => {
              let ImageLength = connectionList[curQuizIndex]?.imageUrl?.length
              // let base64=connectionList[curQuizIndex]?.base64[inx]
              // url=isEmpty(base64)? url : base64
              return (
            
                     <div className={` rounded-xl overflow-hidden ${ImageLength < 3 ? ImageLength < 2 ? "w-full " : "w-2/5 max-h-[200px]" : "w-[30%] max-w-[220px] max-h-[150px]"}  flex flex-row  h-full  justify-center  max-w-[500px] `} key={inx}>

                      <div className={`relative object-cover w-full h-full flex rounded-xl bg-white ${ImageLength < 3 ? ImageLength < 2 ? "" : "min-h-[200px]" : "min-h-[150px]"} overflow-hidden`}  >

                        <img draggable={false} alt={'Connection-img'} src={url} className={`relative bg-white flex m-auto object-cover h-auto rounded-xl  ${ImageLength < 3 ? ImageLength < 2 ? "" : " max-h-[200px]" : " max-h-[150px]"}`} style={{ border: `${ImageLength < 3 ? ImageLength < 2 ? "0.85rem" : "0.75rem" : "5px"}  solid white` }} />
                     
                      </div>
                    
                    </div>

               

              )
            })
          }
        </div>
      </div>

      <center><div className=" flex-row justify-center space-x-1 mt-[0.6rem] sm:mt-[1rem] inline-block text-center"> {inputBoxes}  </div> </center>

    </div>
    </>
  );
};

export default React.forwardRef(Connection);