import { GetDEviceInfo } from "../../backend/GetDeviceInfo";
import Axios from "../../backend/axios";
import { winMsgStatCookie } from "../../backend/messanger";
import { isEmpty } from "./helper";

export class CampaignResponseEvent{

    constructor(prop) {
        this.category = prop.category || ''
        this.score = prop.score || 0
        this.site_id = prop.site_id 
        this.answerlist = prop.answerlist || []
        this.queObj = prop.queObj || []
        this.payload = prop.payload || {}
        this.email = prop.email || []
        this.ConditionState = true
        this.additional_info = prop.additional_info || {}
        this.pollResp = prop?.pollResp || {}
        this.pollData = prop.pollData || []
        this.campaignId = prop.campaignId 
        this.setEmptyStrArr()
    }
    setheader(){
        return {
            headers:{
                "x-ps-id":this.site_id
            }
        }
    }
    isEmptyObj(obj){
        return Object.keys(obj).length > 0
    }
    setEmptyStrArr(){
        this.queObj.map((list)=>this.answerlist.push(''))
    }
    Ruleopertator(leftval='',optionType,rightval){
        // console.log(leftval,optionType,rightval)
        if(optionType === "equal to") return leftval == rightval ? true : false 
        if(optionType === "not equal to") return leftval != rightval ? false : true   
        if(optionType === "contains") return (String(leftval).indexOf(rightval) > -1) ? true : false 
        if(optionType === "does not contains") return (String(leftval).indexOf(rightval) > -1) ? false : true   
    }
    Resultopertator(leftval,optionType,rightval){
        // console.log(leftval,optionType,rightval)
        if(optionType === "equal to") return leftval == rightval ? true : false 
        if(optionType === "graterthen") return leftval > rightval ? true : false   
        if(optionType === "lessthen") return leftval < rightval ? true : false 
        // if(optionType === "does not contains") return (String(leftval).indexOf(rightval) > -1) ? false : true   
    }
    pollRes (index,Innerindex) {
        let PollRES =this.pollData
        PollRES[index] =  PollRES[index].total + 1 
        PollRES[index].option[Innerindex] = PollRES[index].option[Innerindex]+1 
        return PollRES
    }
    
    CheckUserResp(userAns="",queObj,Index,Rules={}){
        // console.log({userAns,queObj,Index,Rules})
        this.answerlist[Index] = userAns
        this.payload[queObj?.question] = userAns

        if (this.category == "poll" || this.category == "quizzes") {
            if(queObj?.optionType == "multioption"|| queObj?.optionType == "yesorno"){
                // console.log(queObj?.answer,userAns,String(queObj?.answer).toLocaleLowerCase() === String(userAns).toLocaleLowerCase())
                this.payload[queObj?.question] = userAns
                return String(queObj?.answer).toLocaleLowerCase() === String(userAns).toLocaleLowerCase() ? this.score+1 : this.score
            }
            if(queObj?.optionType == "email") {
                this.email = userAns
                return this.score
            }
        }
        // console.log(Object.keys(Rules),Rules,"------------->" )

        if(Object.keys(Rules).length > 0) {
            let conditions = Rules?.conditions ? Object.keys(Rules?.conditions) : []
            let conditionsVal = Rules?.conditions ?Object.values(Rules?.conditions) :[]
            let ConditionState = false
            if(conditions.length > 0) {
                conditionsVal.map((list,index) => {
                    if(index !== 0)  {
                        ConditionState = conditionsVal[index-1].condition == "and" ? ConditionState && this.Ruleopertator(this.payload[list?.Que],list?.opertator,list?.value) : ConditionState || this.Ruleopertator(this.payload[list?.Que],list?.opertator,list?.value)
                    }
                    else {
                        ConditionState = this.Ruleopertator(this.payload[list?.Que],list?.opertator,list?.value)
                    }
                })
            }
            if(ConditionState) {
                if(Rules?.action?.type === "Redirect") return window.location.href = Rules?.action?.value ? Rules?.action?.value : "/"
                if(Rules?.action?.type !== "Redirect") return Rules?.action?.value ? Rules?.action?.value : "default"
            }

            return Rules?.DefaultAction ?? "default"
        }

        return "default"
    }

    resultScreen(score,Resultobj){
        // console.log(Resultobj)
        let FindResult = Resultobj.findIndex(list => {
            return this.Resultopertator(score,list?.score?.opertator,list?.score?.value)
        })
        return FindResult > -1 ? FindResult: 0 ; 
    }

    

    async subcripe(payload,recaptcha_token) {
        // console.log(payload,"payload")
        payload['customer'] = '';
        payload['campaign_id'] = this.campaignId;
        payload['site_id'] = this.site_id;
        payload['accepts_marketing'] = '1';
        payload['additional_info'] = JSON.stringify(this.additional_info);
        payload['pollResp'] = this.pollResp;


// pollResp: {
//         u45863585873:{pollresp: }
//     }
         
        // payload["deviceInfo"]= {
        //     "userAgent": "Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Mobile Safari/537.36",
        //     "country": "IN",
        //     "ip": "2401:4900:4de2:2857:ba36:98ed:8afa:add8",
        //     "origin": "localhost:3000"
        // }

        let tempDeviceInfo=await GetDEviceInfo(), deviceInfo=tempDeviceInfo?.deviceInfo || {}

        let scriptElmId=deviceInfo.scriptElmId
        delete deviceInfo?.scriptElmId
        
        let render_path= deviceInfo?.render_path
        if(isEmpty(render_path)) render_path="/"
        else delete deviceInfo?.render_path
        payload['render_path'] = render_path;

        payload["deviceInfo"]=deviceInfo || {}


        return new Promise (async (resolve,reject)=>{
            try{
    
                const res = await Axios.post(`/client/user/subscribe` , payload, this.setheader());

                if(res.status === 200){ 
                    winMsgStatCookie({siteId:this.site_id,campaignId:this.campaignId,cookieType:"subscribed",scriptElmId:scriptElmId})
                    resolve(res.data);
                }
    
            }catch(err){
                console.log(err);
                reject(err);
            }
        })
    } 
}